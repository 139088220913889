/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable no-var */
import { Input } from '@angular/core';
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {

  constructor() { }
  @Input() username = null;
  @Input() isChat = false;
  @Input() hideNav = false;
  ngOnInit() {}

  toggleSidebar(): void {
    var sidebar = document.querySelector("app-sidebar")
    if(sidebar?.classList?.contains("hide")) {
      document.querySelector("app-sidebar")?.classList.remove("hide")
    } else {
      document.querySelector("app-sidebar")?.classList.add("hide")
    }
  }

}
