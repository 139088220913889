/* eslint-disable @typescript-eslint/quotes */
import { Component, OnInit } from '@angular/core';
import { ApplicationStateService } from '../_services';
import mixpanel from "mixpanel-browser";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit {

  username = '';
  constructor() {
    this.username = ApplicationStateService.getUser()?.firstName;
    ApplicationStateService.setPageTitle("Home");
   }

  ngOnInit() {
    mixpanel.init(environment.mixpanel_token)
    mixpanel.identify(ApplicationStateService.getUser().email)
    mixpanel.track("Welcome Page", {
      "device_type": "browser"
    })
  }

}
