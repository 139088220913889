/* eslint-disable prefer-const */
/* eslint-disable no-underscore-dangle */
import { ApplicationStateService } from './../_services/application.state.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../_services';



@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private _userService: UserService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user =  ApplicationStateService.getUser();
        let token = ApplicationStateService.getToken();
        if(user == null || token == null) {
            this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }

        let isExpired = new Date(user.expire_in) < new Date();
        if(user.is_guest && isExpired) {
            ApplicationStateService.setUser(null);
            ApplicationStateService.setToken(null);
            ApplicationStateService.setGuest(false);
            this.router.navigate(['/auth/login'], {
                queryParams: { "status": "expired" },
                queryParamsHandling: "merge"
            })
            return false;
        }

        let refreshUser = this._userService.refresh(token.refresh).pipe().subscribe(
            data => {
                token.access = data.access;
                ApplicationStateService.setToken(token);
            },
            error => {
            }
        )
        if(refreshUser['syncErrorThrown']) {
            this.router.navigate(['/auth/login'], {
                queryParams: { "status": "expired" },
                queryParamsHandling: "merge"
            })
            return false;
        }
        return true;
    }



}
