/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class ResponseEmergencyService {

  constructor(
    private http: HttpClient
  ) { }

  createHeader() {
    return { 'Content-Type': 'application/json'};
  }

  sendText(data): Observable<any> {
    return this.http.post<Response>(`${environment.apiUrl}/api/emergency/text/`,JSON.stringify(data),{'headers' :this.createHeader()});
  }
}
