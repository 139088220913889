import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-response-carousel',
  templateUrl: './response-carousel.component.html',
  styleUrls: ['./response-carousel.component.scss'],
})
export class ResponseCarouselComponent implements OnInit {

  @Input() response: any;
  @Output() chosenCarousel = new EventEmitter();
  constructor() { }

  ngOnInit() {}

  testing(){
    console.log('testing');
  }

}
