import { PrivacyComponent } from './privacy/privacy.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { ChatComponent } from './chat/chat.component';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_helpers/auth.guard';
import { AboutComponent } from './about/about.component';
import { FeedbackComponent } from './_shared/feedback/feedback.component';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then( m => m.AuthModule)
  },
  {
    path: 'journal',
    loadChildren: () => import('./journal/journal.module').then( m => m.JournalModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'gratitude',
    loadChildren: () => import('./gratitude/gratitude.module').then( m => m.GratitudeModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'thought',
    loadChildren: () => import('./thought/thought.module').then( m => m.ThoughtModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'emergency-plan',
    loadChildren: () => import('./emergency-plan/emergency-plan.module').then( m => m.EmergencyPlanModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'medicines',
    loadChildren: () => import('./medicine/medicine.module').then( m => m.MedicineModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'chat',
    component: ChatComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'chat/:action',
    component: ChatComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'welcome',
    component: WelcomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '',
    component: AboutComponent,
  },
  {
    path: 'about',
    component: AboutComponent,
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
  },
  {
    path: 'feedback',
    component: FeedbackComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: '**',
    redirectTo: 'welcome',
    pathMatch: 'full'
  },
  {
    path: 'login',
    redirectTo: 'auth/login',
    pathMatch: 'full'
  },
  {
    path: 'register',
    redirectTo: 'auth/register',
    pathMatch: 'full'
  },
  {
    path: 'forgot_password',
    redirectTo: 'auth/forgot_password',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
