import { ResponseNoLocationComponent } from './response-no-location/response-no-location.component';
import { SharedModuleModule } from './shared-module/shared-module.module';
import { AuthModule } from './auth/auth.module';
import { SidebarComponent } from './sidebar/sidebar.component';
/* eslint-disable max-len */
import { DashboardComponent } from './dashboard/dashboard.component';
import { ResponseCarouselComponent } from './response-carousel/response-carousel.component';
import { ResponseCardComponent } from './response-card/response-card.component';
import { SuggestionChipComponent } from './suggestion-chip/suggestion-chip.component';
import { NavComponent } from './nav/nav.component';
import { InputMessageComponent } from './errors/input-message/input-message.component';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { WelcomeComponent } from './welcome/welcome.component';
import { ChatComponent, NgbdModalContent } from './chat/chat.component';

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { TopNearestFacilityPipePipe } from './pipes/top-nearest-facility-pipe.pipe';
import { ResponseYoutubeComponent } from './response-youtube/response-youtube.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { ErrorMessage } from './_services/errormessage.service';
import { AboutComponent } from './about/about.component';
import { FeedbackComponent } from './_shared/feedback/feedback.component';
import { NgxChartsModule }from '@swimlane/ngx-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';



@NgModule({
    declarations: [AppComponent, ChatComponent, WelcomeComponent, TopNearestFacilityPipePipe,
        SuggestionChipComponent, ResponseCardComponent, ResponseCarouselComponent, ResponseYoutubeComponent, DashboardComponent, SidebarComponent,AboutComponent,NgbdModalContent,FeedbackComponent,ResponseNoLocationComponent],
    imports: [BrowserModule, IonicModule.forRoot(), HttpClientModule, FormsModule, ReactiveFormsModule, AppRoutingModule, SharedModuleModule,NgbModule,BrowserAnimationsModule,NgxChartsModule],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi:true},
        ErrorMessage,
        Geolocation,
        TopNearestFacilityPipePipe
    ],
    bootstrap: [AppComponent],
    exports: [InputMessageComponent, NavComponent] // <== export the component you want to use in another module
})
export class AppModule {}
