/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable quote-props */
/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap, first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable({ providedIn: 'root' })
export class ThoughtService {
    constructor(private http: HttpClient) { }

    createHeader() {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        return { 'Content-Type': 'application/json'};
      }

    addThought(msg): Observable<any> {
      return this.http.post<Response>(`${environment.apiUrl}/thought-record/`,JSON.stringify(msg),{'headers' :this.createHeader()});
    }
    retrieveRecentThoughtEntries(): Observable<any> {
      return this.http.get<Response>(`${environment.apiUrl}/thought-record`).pipe(map( res => res['data']));
    }
    retrieveThoughtEntry(id): Observable<any> {
      return this.http.get<Response>(`${environment.apiUrl}/thought-record/${id}`).pipe(map( res => res['data']));
    }
    archiveThoughtEntry(id): Observable<any> {
      return this.http.delete<Response>(`${environment.apiUrl}/thought-record/${id}`).pipe(map( res => res['data']));
    }
    updateThoughtEntry(id,msg): Observable<any> {
      return this.http.put<Response>(`${environment.apiUrl}/thought-record/${id}`,JSON.stringify(msg),{'headers' :this.createHeader()});
    }
}
