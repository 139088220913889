/* eslint-disable no-trailing-spaces */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable quote-props */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap, first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApplicationStateService } from './application.state.service';
import { User } from '../_types/User';


@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }

    createHeader() {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        return { 'Content-Type': 'application/json'};
    }
    login(user: User): Observable<any> {
         return this.http.post<Response>(`${environment.apiUrl}/auth/login/`,JSON.stringify(user),{'headers' :this.createHeader()});
    }
    forgotpassword(user: User): Observable<any> {
         return this.http.post<Response>(`${environment.apiUrl}/auth/forgotpassword/`,JSON.stringify(user),{'headers' :this.createHeader()});
    }
    resetpassword(user: User, hash: string): Observable<any> {
         return this.http.post<Response>(`${environment.apiUrl}/auth/resetpassword/${hash}`,JSON.stringify(user),{'headers' :this.createHeader()});
    }
    refresh(refreshToken: string): Observable<any> {
        return this.http.post<Response>(`${environment.apiUrl}/auth/login/refresh/`,{"refresh": refreshToken},{'headers' :this.createHeader()});
    }

    loginAsGuest(): Observable<any> {
      return this.http.get<Response>(`${environment.apiUrl}/auth/guest/`).pipe(map( res => res));
    }
    register(user: User): Observable<any> {
        return this.http.post<Response>(`${environment.apiUrl}/auth/register/`,JSON.stringify(user),{'headers' :this.createHeader()});
    }
    userInfo(): Observable<any> {
      return this.http.get<Response>(`${environment.apiUrl}/auth/user/current/`).pipe(map( res => res['current_user']));
    }
}
