/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable curly */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/type-annotation-spacing */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FeedbackService } from 'src/app/_services/feedback.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit {

  form: FormGroup;
  feedbackSent:boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private _formBuilder: FormBuilder,
    private _feedbackService: FeedbackService
  ) { }

  ngOnInit() {
    this.form = this._formBuilder.group({
      star: [''],
      feedback: ['']
    });
  }

  submitFeedback(){
    this._feedbackService.add(this.form.value).pipe().subscribe(
      res => {
        if(res.status === "success")
          this.feedbackSent = true;
          //this.activeModal.close('Close click')
      },
      error =>{
      });
  }


}
