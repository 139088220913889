/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/quotes */
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ErrorMessage } from '../_services/errormessage.service';
import { ApplicationStateService } from '../_services';



@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private route: Router, private errorMessage: ErrorMessage) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(request)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            let errorMsg = '';
            if (error.error instanceof ErrorEvent)
              errorMsg = `Error: ${error.error.message}`;
            else {
              errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
              switch (error.status) {
                case 401:
                  //sse.stop()
                  errorMsg =  error?.error?.detail === 'No active account found with the given credentials' ? 'Incorrect email or password.' : 'Session expired, please log in to continue' ;
                  ApplicationStateService.setUser(null);
                  ApplicationStateService.setToken(null);
                  this.route.navigateByUrl("/login");
                  break;
                case 422:
                  //this._toastr.error('Please contact admin', ' Unable to process request', {timeOut: 5000, });
                  break;
                case 413:
                  //this._toastr.error('File exceeds size limit', ' Unable to process request', {timeOut: 5000, });
                  break;
                case 504:
                  //this._toastr.error('Server Timeout', 'Unable to process request', {timeOut: 5000, });
                  break;
                case 0:
                  //sse.stop()
                  //this.route.navigateByUrl("/login");
                  errorMsg = "Site is currently unavailable.";
                  // this._toastr.error('Server was unavailable','Please try again', {timeOut: 5000, });
                  // this._loaderService.changeLoadingState(false);
                  break;
              }

            }
            return throwError({
              "message": errorMsg,
              "response": error?.error
            });
          })
        );
    }
}
