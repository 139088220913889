// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: 'https://ccapco.pythonanywhere.com',
  // apiUrl: 'http://localhost:8000',
  apiUrl: 'https://dispo-api.telehealth.ph',
  login: "jabrera36110",
  password: "tQHzL4gr",
  telnyx_token: "KEY01838D078A9886F6B73DE4E8E0A81AE5_3BNKzN1Dv7cFtN3nGlb87B",
  destinationNumber: "+63",
  mixpanel_token: "bdc244ac1107a99a8b581d4c262c884e"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
