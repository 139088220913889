/* eslint-disable max-len */
/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/naming-convention */
import { ApplicationStateService } from "./../_services/application.state.service";
import { UserService } from "./../_services/user-service";
import { environment } from "./../../environments/environment";
import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Token } from "../_types/Token";



@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private _userService: UserService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const currentToken: Token = ApplicationStateService.getToken();
        if (!request.url.includes("auth/login") && 
            !request.url.includes("auth/register") && 
            !request.url.includes("auth/guest") && 
            !request.url.includes("api/province") && 
            !request.url.includes("api/municipality") && 
            !request.url.includes("api/barangay") && 
            !request.url.includes("auth/forgotpassword") && 
            !request.url.includes("auth/resetpassword")) {
            request = request.clone({
                "setHeaders": {
                    "Authorization": `Bearer ${currentToken?.access}`,
                    "Content-Type": "application/json"
                }
            });
        }
        return next.handle(request);
    }
}

