/* eslint-disable @typescript-eslint/dot-notation */
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationStateService } from '../_services';
import { FeedbackComponent } from '../_shared/feedback/feedback.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Input() selected = '';
  @Input() isAuthenticated;
  @Input() isGuest;
  @ViewChild('search') search: ElementRef;

  constructor(private router: Router,private modalService: NgbModal) {

   }

  ngOnInit() {}

  signOut(){
    ApplicationStateService.setUser(null);
    ApplicationStateService.setToken(null);
    ApplicationStateService.setGuest(false);
    setTimeout(x => {this.router.navigate(['/auth/login']); }, 500);
  }

  open() {
    const modalRef = this.modalService.open(FeedbackComponent);
  }

  toggleSidebar() {
    document.querySelector("app-sidebar").classList.remove("show")
  }


}
