/* eslint-disable no-var */
/* eslint-disable curly */
/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { LocationService } from './../_services/location.service';
import { ResponseNoLocationComponent } from './../response-no-location/response-no-location.component';
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { EventEmitter, Input, OnDestroy, Output } from '@angular/core';
/* eslint-disable object-shorthand */
/* eslint-disable prefer-const */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-underscore-dangle */
import { first } from 'rxjs/operators';
import { ChatService } from './../_services/chat-service';
import { ApplicationStateService } from './../_services/application.state.service';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { TopNearestFacilityPipePipe } from '../pipes/top-nearest-facility-pipe.pipe';
import { LOCATION_DENIED, LOCATION_GRANTED, LOCATION_PROMPT, SENT_BY_BOT, SENT_BY_USER } from './chat.constants';
import { SCORE_0,SCORE_1_9,SCORE_10_19,SCORE_20_29,SCORE_30__ } from './assessment.constants';
import { Subscription } from 'rxjs/internal/Subscription';
import { Language } from '../_enums/Language';
import { NgbActiveModal, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ThoughtService } from '../_services/thought-service';
import { CallStatus } from '../_enums/CallStatus';
import { INotification, TelnyxRTC } from '@telnyx/webrtc';
import { environment } from 'src/environments/environment';
import { ResponseEmergencyService } from '../_services/response-emergency.service';
import { TextStatus } from '../_enums/TextStatus';
import { timer } from 'rxjs';
import { map } from 'rxjs/operators';
import mixpanel from "mixpanel-browser";


@Component({
	selector: 'ngbd-modal-content',
	templateUrl: './modal.component.html',
})
export class NgbdModalContent implements OnInit {
	@Input() name;
  @Input() data;

  thoughtForm: UntypedFormGroup;
  isAdd = true;
  @Output() refreshData: EventEmitter<any> = new EventEmitter();
  currentDate = new Date();
  atHospital: boolean = false;
  hospitals: any[] = [];
  selectedHospital: any = null;
  emergencyHotlines: any[] = [];
  selectedHotline: any = null;

  lang: string = ApplicationStateService.getLang();
  callStatusEnum = CallStatus;

  callStatus: CallStatus = CallStatus.SELECT_NUMBER;
  client: TelnyxRTC;
  notification: any;

  textStatus:TextStatus = TextStatus.SELECTING;
  textStatusEnum = TextStatus;
  callMinutes = "00";
  callSeconds = "00";



	constructor(private _formBuilder: UntypedFormBuilder,public activeModal: NgbActiveModal,private _thoughtService: ThoughtService,private _responseEmergencyService: ResponseEmergencyService) {
    this.thoughtForm = this._formBuilder.group({
      q1: ["", Validators.required],
      q2: ["", Validators.required],
      q3: ["", Validators.required],
      q4: ["", Validators.required],
      q5: ["", Validators.required],
    });

    ApplicationStateService.setPageTitle("Talk with Dispo");
    const client = new TelnyxRTC({
      login: environment.login,
      password: environment.password,
      login_token: environment.telnyx_token,
    });

    client.remoteElement = "audioStream";
    client.enableMicrophone();

    client.on("telnyx.ready", () => {
      this.callStatus = CallStatus.SELECTED;
    });

    client.on("telnyx.error", (error: any) => {
      console.error(error);
      this.callStatus = CallStatus.ERROR;
      this.client.disconnect();
    });

    client.on("telnyx.socket.close", (close: any) => {
      console.error(close);
      this.callStatus = CallStatus.ERROR;
      if (client) {
        client.off("telnyx.error");
        client.off("telnyx.ready");
        client.off("telnyx.notification");
        client.off("telnyx.socket.close");
      }
    });

    client.on("telnyx.notification", (notification: INotification) => {
      switch (notification.type) {
        case "callUpdate":
          if (notification.call) {
            this.notification = notification;
            if(["new", "requesting", "trying", "early"].includes(this.notification.call.state))
              this.callStatus = CallStatus.CONNECTING;
            else if(this.notification.call.state == "active") {
              let startDate = new Date();
              this.callStatus = CallStatus.CONNECTED;
              timer(1000, 1000)
                .pipe(
                  map((x: number) => {
                    const newDate = new Date(startDate.getTime());
                    newDate.setSeconds(newDate.getSeconds() + x);
                    return newDate;
                  })
                )
                .subscribe(t => {
                  this.callMinutes = Math.floor(Math.abs(startDate.valueOf() - t.valueOf())/1000 % 3600 / 60).toString().padStart(2,'0'); 
                  this.callSeconds = Math.floor(Math.abs(startDate.valueOf() - t.valueOf())/1000 % 60).toString().padStart(2,'0');
                });
            }
            else if(["hangup", "destroy"].includes(this.notification.call.state))
              this.callStatus = CallStatus.RECIPIENT_HANGED_UP;
          }
          break;
        case "userMediaError":
          // Permission denied or invalid audio/video params on `getUserMedia`
          alert(
            `${notification.error}. \nPlease check if your devices (i.e. microphone or webcam) are plugged.`
          );
          break;
        default:
          break;
      }
    });

    this.client = client;

  }

  ngOnInit() {
    console.log('data',this.data)
    this.atHospital = this.data.at_hospital;
    this.hospitals = this.data.hospitals;
    this.emergencyHotlines = this.data.emergency_hotlines;

  }

  sendText(){
    var payload = {
      "hospital_uuid": this.selectedHospital,
      "locale": ApplicationStateService.getLang()
    }
    this.textStatus = TextStatus.SENDING;
    this._responseEmergencyService.sendText(payload).subscribe(res => {
      if(res) {
        this.textStatus = TextStatus.SENT;
        this.activeModal.close('Close click')
      } else {
        console.log("error: ",res)
      }
    });
  }

  connect() {
    this.callStatus = CallStatus.SELECTING;
    this.client.connect();
  }

  call() {
    if (this.client) {
      this.callStatus = CallStatus.CONNECTING;
      this.client.newCall({
        destinationNumber: this.selectedHotline
      });
    }
  }

  hangup() {
    if (this.notification && this.notification.call) {
      this.callStatus = CallStatus.CALLER_HANGED_UP;
      this.notification.call.hangup();
    }
  }
}


@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit,OnDestroy {

  @ViewChild('language') language;
  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
     if(event['key'] == 'Enter' && !this.chatBotLoading){
      this.sendMessage();
     }
  }

  // line chart options
  legend: boolean = true;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  timeline: boolean = true;

  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };


  // load more data when user reaches the topmost of messages-container div
  @HostListener('scroll', ['$event'])
  onScroll(event) {
    let scroll = event.srcElement.scrollTop;
    const chatContainer = document.getElementById("chat");
    if(scroll != 0) return false;
    const firstMessage = chatContainer.querySelectorAll(".message-container")[0];
    const id = this.chatList?.[0]?.['id'];
    this.loadingPreviousData = true;
    this._chatService.retrieveRecentMessages(id,10).pipe(first()).subscribe(
      data => {
        if(data.length > 0){
          let updatedChatList = data.concat(this.chatList)
          this.chatList = updatedChatList;
          chatContainer.scrollTop = firstMessage.getBoundingClientRect().top - 130;
        }
        this.loadingPreviousData = false;
      }
    );
  }

  @ViewChild('message') message;
  username = '';
  chatForm: UntypedFormGroup;
  chatList: any = [];
  chatBotLoading = false;
  lat = null;
  long = null;
  facilities = [];
  SENT_BY_BOT =  SENT_BY_BOT;
  SENT_BY_USER =  SENT_BY_USER;
  loadingPreviousData = false;
  private routeSub: Subscription;
  emergencySituationFacilities = [];
  public chart: any;
  scores =  [];
  showScores = false;
  assessment = '';

  constructor(
    private _chatService: ChatService,
    private _formBuilder: UntypedFormBuilder,
    private _router: Router,
    private  _activatedRoute: ActivatedRoute,
    private geolocation: Geolocation,
    private _topNearestFacilityPipePipe: TopNearestFacilityPipePipe,
    private modalService: NgbModal,config: NgbModalConfig,
    private _location: LocationService
  ) {
  }




  async ngOnInit() {
    mixpanel.init(environment.mixpanel_token)
    mixpanel.identify(ApplicationStateService.getUser().email)
    mixpanel.track("Chat Page", {
      "device_type": "browser"
    })

    this.routeSub = this._activatedRoute.params.subscribe(params => {
      switch(params['action']){
        case 'sos':
          this.sendSOS();
          break;
      }
    });

    this.username = ApplicationStateService.getUser()?.firstName;
    this.chatForm = this._formBuilder.group({
      text: ['',Validators.required],
    });


    // check permission and determine whether to show ask for permission or start tracking user's location
    this._location.checkPermission().pipe(first()).subscribe(data =>{
      console.log('this._location.checkPermission',data)
      if(data == LOCATION_GRANTED){
        const watch = this.geolocation.watchPosition();
        watch.subscribe((loc) => {
          this._location.setLocation(loc?.['coords'])
        });
      }
      else if (data == LOCATION_PROMPT){
        const modalRef = this.modalService.open(ResponseNoLocationComponent);
        modalRef.componentInstance.content = { title:"Location Permission", content:"Dispo requires to retrieve your location for us to serve you better. During the conversation with Dispo, we will suggest for the nearest facility and psychiatrists in your area." };
      }
    });

    this._chatService.retrieveRecentMessages().pipe(first()).subscribe(
      data => {
        this.chatList = data
        setTimeout(() => {this.scroll()}, 100);
        this.scroll();
      }
    );
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }


  sendMessage(messageData = null,sos=false) {
    //this.exportPDF('sample');
    this
    messageData = messageData == null ? this.message.nativeElement.value : messageData
    const payload = {
      message: messageData,
      data: {content: messageData},
      sent_by: SENT_BY_USER,
      latitude: this._location.locationValue?.latitude,
      longitude: this._location.locationValue?.longitude,
      locale: ApplicationStateService.getLang()
    };
    this.chatList.push(payload);
    this.chatBotLoading = true;
    setTimeout(() => {this.scroll()}, 100);
    this.scroll();
    this._chatService.sendMessage(payload).pipe(first()).subscribe(
      data => {
        this.message.nativeElement.value = '';
        for(let message of data?.messages) {
          if(message?.response_type === 'Facilities') {
            let chatItem2 = {message: message.message,is_json: true, sent_by:SENT_BY_BOT};
            chatItem2['data'] = message;
            chatItem2['message'] = null;
            this.chatList.push(chatItem2);
          } else if(['Message', 'Carousel', 'YouTubeVideo', 'SuggestionChips', 'Card'].indexOf(message?.response_type) >= 0) {
            let chatItem2 = message;
            chatItem2['sent_by'] = SENT_BY_BOT;
            chatItem2['data'] = message;
            this.chatList.push(chatItem2);
          } else if (message?.response_type == 'journal') {
            this._router.navigate(['/journal']);
          }
          else if (message?.response_type == 'gratitude-list') {
            this._router.navigate(['/gratitude']);
          }
          else if (message?.response_type == 'emergency-plan') {
            this._router.navigate(['/emergency-plan']);
          }
          else if (message?.response_type == 'thought-record') {
            this._router.navigate(['/thought']);
          }
          else if (message?.response_type == 'NoLocation') {
            const modalRef = this.modalService.open(ResponseNoLocationComponent);
            modalRef.componentInstance.content = { title: "Location Permission Required", content:"Dispo was unable to send you information since it requires you to allow Location Permission. You may enable the location by clicking the button below and try to ask Dispo again." };
          }
          else if (message?.response_type == 'EmergencySituation') {
            const modalRef = this.modalService.open(NgbdModalContent,{
              backdrop: 'static',
              keyboard: false
            });
            modalRef.componentInstance.data =  message;
          }
          else if (message?.response_type == 'DownloadPDF') {
            if(message?.scores?.length > 0){
              this.showScores = true;
              let newScores = [];
              for(let index = 0;index < message?.scores.length ; index++){
                newScores.push({name: `${index}`, value: message?.scores[index]})
              }
              this.scores = [
                {
                  "name": "My Results",
                  "series": newScores
                },
              ]

              // determine last score
              let lastScore =  newScores[newScores?.length - 1]?.value
              console.log('lastScore',lastScore)
              if(lastScore == 0){
                this.assessment = SCORE_0
              }
              else if(lastScore>= 1 && lastScore<= 9){
                this.assessment = SCORE_1_9
              }
              else if(lastScore>= 10 && lastScore<= 19){
                this.assessment = SCORE_10_19
              }
              else if(lastScore>= 20 && lastScore<= 29){
                this.assessment = SCORE_20_29
              }
              else if(lastScore>= 30){
                this.assessment = SCORE_30__;
              }
              else{
                this.assessment = ''
              }
              console.log('this.assessment',this.assessment)
              setTimeout( x=>{
                this.exportPDF()
              },1000)
            }
          }
          else {
            if(message.hasOwnProperty("response_type")) {
              // unsupported response_type
              this.chatList.push({
                is_json: true,
                data: {
                  response_type: "Message",
                  content: "Unsupported response type `"+message.response_type+"`"
                },
                sent_by: SENT_BY_BOT
              });
            }
          }
        }
        this.chatBotLoading = false;
        setTimeout(() => {this.scroll() }, 100);
        sos ? this._router.navigate(["/chat"]) : null;
      }, error => {
        console.error('Error in send message', error);
        this.message.nativeElement.value = '';
        this.chatList.push({
          is_json: true,
          data: {
            response_type: "Message",
            content: "I'm sorry but I am having trouble processing your message. Please try again later."
          },
          sent_by: SENT_BY_BOT
        });
        this.chatBotLoading = false;
        setTimeout(() => {this.scroll() }, 100);
        sos ? this._router.navigate(["/chat"]) : null;
      }
    );
  }

  addResponseOfBotToChatList(data){
    for(let message of data?.message){
      let chatItem2 = data;
      this.chatList.push(chatItem2);
    }
  }

  scroll(){
    const objDiv = document.getElementById("chat");
    objDiv.scrollTop = objDiv.scrollHeight+200;
  }

  sendSOS(){
    setTimeout( () => { this.message.nativeElement.value = "sos"; this.sendMessage(null,true) }, 1000 );
  }

  // exportPDF(tableId){
  //   const table = document.getElementById(tableId);
  //   // clone table and add table inside #print-container
  //   const elem = document.querySelector(`#${tableId}`);
  //   const clone: any = elem.cloneNode(true);
  //   const container: any = document.getElementById('pdf_printing_content');
  //   //container.innerHTML = "";
  //   //container.appendChild(clone);
  //   container.innerHTML = clone.innerHTML
  //   window.print();
  //   //container.innerHTML = "";
  // }

  exportPDF(){
    let markup = document.documentElement.innerHTML;
    let prtContent = document.getElementById("pdf_printing_content");
    markup = markup.replace(new RegExp(/<app-root[^>]*>((.|[\n\r])*)<\/app-root>/gm),prtContent.innerHTML);
    let WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
    WinPrint.document.write(markup)
    WinPrint.document.close();
    setTimeout( x=> {
      WinPrint.focus();
      WinPrint.print();
    },2000)
  }
}
