/* eslint-disable quote-props */
/* eslint-disable no-trailing-spaces */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  constructor(
    private http: HttpClient,
  ) { }

  createHeader() {
    // eslint-disable-next-line @typescript-eslint/naming-convention
  return { 'Content-Type': 'application/json'};
  }

  add(data): Observable<any> {
    return this.http.post<Response>(`${environment.apiUrl}/api/feedback/`,JSON.stringify(data),{'headers' :this.createHeader()});
  }
}
