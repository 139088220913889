/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable max-len */
import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, AbstractControl } from '@angular/forms';

@Component({
  selector: 'error-input-message',
  templateUrl: './input-message.component.html',
  styleUrls: ['./input-message.component.scss']
})
export class InputMessageComponent implements OnInit {

  @Input() form: UntypedFormGroup;
  @Input() key: string;
  error: Object;

  constructor() { }

  ngOnInit(): void {
    // console.log('inside error InputMessageComponent')
    // console.table(this.form[this.key].errors);
     //console.log(this.key)
    //yearQuarterForm.controls
    this.error = this.form.controls[this.key].errors;
  }

  transformKeyToLabel(val: string){
    //return val.replace(/([a-z0-9])([A-Z])/g, '$1 $2').toLowerCase().replace('id','');
    return val.toLowerCase().replace('confirmpassword','confirm password').replace(/\b\w/g, s => s.toUpperCase()).replace('id','');

  }

  checkIfKeyIsYearAndQuarterHasAsyncError(){
    //console.log('logging checkIfKeyIsYearAndQuarterHasAsyncError() ')
    //console.log(this.key == 'year' && this.form.controls['quarter'].errors!=null && this.form.controls['quarter'].errors.hasOwnProperty('nonUniqueYearQuarter'))
    return (this.key == 'year' && this.form.controls['quarter'].errors!=null && this.form.controls['quarter'].errors.hasOwnProperty('nonUniqueYearQuarter'));
  }

  checkIfKeyIsCurrentPasswordAndHasAsyncError(){
    //log('logging checkIfKeyIsYearAndQuarterHasAsyncError() ')
    //console.log(this.key == 'year' && this.form.controls['quarter'].errors!=null && this.form.controls['quarter'].errors.hasOwnProperty('nonUniqueYearQuarter'))
    return (this.key == 'currentPassword' && this.form.controls['currentPassword'].errors!=null && this.form.controls['currentPassword'].errors.hasOwnProperty('invalidPasswordAsync'));
  }



}
