/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable quote-props */
/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap, first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MessagePayload } from '../_types/MessagePayload';


@Injectable({ providedIn: 'root' })
export class ChatService {
    constructor(private http: HttpClient) { }

    createHeader(): { "Content-Type": string } {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        return { 'Content-Type': 'application/json'};
      }

    sendTestMessage(msg: string): Observable<any> {
        return this.http.get<Response>(`http://127.0.0.1:8000/chat/test-api/?text=${msg}`).pipe();
    }

    sendMessage(msg: MessagePayload): Observable<any> {
      return this.http.post<Response>(`${environment.apiUrl}/chat/send`,JSON.stringify(msg),{'headers' :this.createHeader()});
    }

    retrieveRecentMessages(messageuuid = null,limit = null): Observable<any> {
      const url = messageuuid != null && limit != null ? `${environment.apiUrl}/chat/history?messageId=${messageuuid}&limit=${limit}` : `${environment.apiUrl}/chat/history`;
      return this.http.get<Response>(url).pipe(map( res => res['data']));
    }
}
