/* eslint-disable @typescript-eslint/quotes */
import { Language } from './_enums/Language';
/* eslint-disable max-len */
/* eslint-disable no-trailing-spaces */
import { ApplicationStateService } from './_services/application.state.service';
import { OnDestroy } from '@angular/core';
/* eslint-disable no-underscore-dangle */
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy{
  username = '';
  selectedModule: any = null;
  listener;
  hideSideBar = false;
  isChat = false;
  user = null;
  isGuest = false;
  localeLang = null;
  localeLangList = Language;
  pageTitle = '';

  constructor(private _router: Router) {
    this.listener = this._router.events.subscribe((val) => {
      this.user = ApplicationStateService.getUser();
      this.hideSideBar = this._router.url.includes('auth') || this._router.url.includes('home') || this._router.url.includes('about') || this._router.url.includes('privacy') || this._router.url === '/';
      this.isChat = this._router.url.includes('chat');
      this.username = this.user?.firstName;
      this.isGuest = ApplicationStateService.isGuest();
      this.localeLang = ApplicationStateService.getLang() == null ? 'en' : ApplicationStateService.getLang() ;
      this.pageTitle = ApplicationStateService.getPageTitle();
    });
  }
  ngOnDestroy(): void {
    this.listener.unsubscribe();
  }

  sendSOS(){
    this._router.navigate(['/chat/sos']);
  }

  onLangChange(deviceValue) {
    ApplicationStateService.setLang(deviceValue == Language.ENGLISH ? Language.ENGLISH : Language.FILIPINO);
  }

  toggleSidebar() {
    document.querySelector("app-sidebar").classList.add("show")
  }
}
