/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable arrow-body-style */
/* eslint-disable no-trailing-spaces */
/* eslint-disable prefer-const */
/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/quotes */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'topNearestFacilityPipe'
})
export class TopNearestFacilityPipePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
      let lat = 14.61139286176861;
      let long= 121.03060551848651;
      // return this.testData.map( x=> {this.distance(lat,x.latitude,long,x.longitude),x.name,x.id} );
      let list = value.map( x=> ({
        distance:this.distance(args[0],+x.latitude,args[1],+x.longitude),
        name:x.name,
        id:x.id,
        lat:x.latitude,
        long:x.longitude,
      })
      );
      list = list.sort((a,b) => a.distance - b.distance);
      return list;
  }

  distance(lat1,lat2, lon1, lon2){

  // The math module contains a function
  // named toRadians which converts from
  // degrees to radians.
  lon1 =  lon1 * Math.PI / 180;
  lon2 = lon2 * Math.PI / 180;
  lat1 = lat1 * Math.PI / 180;
  lat2 = lat2 * Math.PI / 180;

  // Haversine formula
  let dlon = lon2 - lon1;
  let dlat = lat2 - lat1;
  let a = Math.pow(Math.sin(dlat / 2), 2)
  + Math.cos(lat1) * Math.cos(lat2)
  * Math.pow(Math.sin(dlon / 2),2);

  let c = 2 * Math.asin(Math.sqrt(a));

  // Radius of earth in kilometers. Use 3956
  // for miles
  let r = 6371;

  // calculate the result
  return(c * r);
  }

}
