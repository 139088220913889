/* eslint-disable @typescript-eslint/naming-convention */
export enum CallStatus {
  SELECT_NUMBER,
  SELECTING,
  SELECTED,
  CONNECTING,
  CONNECTED,
  CALLER_HANGED_UP,
  RECIPIENT_HANGED_UP,
  ERROR
}
