/* eslint-disable no-trailing-spaces */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-suggestion-chip',
  templateUrl: './suggestion-chip.component.html',
  styleUrls: ['./suggestion-chip.component.scss'],
})
export class SuggestionChipComponent implements OnInit {

  @Input() item: any;

  @Output() chosenSuggestionChip = new EventEmitter();


  constructor() { }

  ngOnInit() {}

  sendMessage() {

  }

}
