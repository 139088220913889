import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-response-youtube',
  templateUrl: './response-youtube.component.html',
  styleUrls: ['./response-youtube.component.scss'],
})
export class ResponseYoutubeComponent implements OnInit {

  @Input() response = null;
  constructor(private domSanitizer: DomSanitizer) {}


  ngOnInit() {}

  transform(url) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
