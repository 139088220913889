/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/quotes */
import { Injectable } from "@angular/core";
import { Language } from "../_enums/Language";
import { Token } from "../_types/Token";
import { User } from "../_types/User";
@Injectable({providedIn: 'root'})
export class ApplicationStateService {

  constructor(){}

  static email: string;

  static getEmail(): string {
    return this.email;
  }

  static setEmail(val: string) : void {
    this.email = val;
  }

  static setUser(user: User): void {
    localStorage.setItem('currentUser', JSON.stringify(user));
  }

  static getUser(): User {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  static setToken(token): void {
    localStorage.setItem('token', JSON.stringify(token));
  }

  static getToken(): Token {
    return JSON.parse(localStorage.getItem('token'));
  }

  static setLang(value : Language): void {
    localStorage.setItem('lang', value);
  }

  static getLang() : Language {
    if (localStorage.getItem('lang') == null || localStorage.getItem('lang') == Language.ENGLISH)
      return Language.ENGLISH;
    return Language.FILIPINO;
  }

  static setGuest(token: boolean): void {
    localStorage.setItem('guest', token.toString());
  }

  static isGuest(): boolean {
    return JSON.parse(localStorage.getItem('guest'));
  }

  static getPageTitle() {
    return localStorage.getItem('page_title');
  }

  static setPageTitle(value: string): void {
    localStorage.setItem('page_title', value);
  }
}
