/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/dot-notation */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationStateService } from '../_services';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {

  username = '';
  selectedModule: any = null;
  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
  ) {
    //this.selectedModule.find( x=> x.route === this._activatedRoute.snapshot.params['form']);
    this.selectedModule = this._activatedRoute.snapshot.params['form'];
   }

  ngOnInit() {
    this.username = ApplicationStateService.getUser()?.firstName;
  }

}
