import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-response-card',
  templateUrl: './response-card.component.html',
  styleUrls: ['./response-card.component.scss'],
})
export class ResponseCardComponent implements OnInit {

  @Input() response: any;
  constructor() { }

  ngOnInit() {}

}
