import { NavComponent } from './../nav/nav.component';
import { InputMessageComponent } from './../errors/input-message/input-message.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [InputMessageComponent,NavComponent],
  exports: [InputMessageComponent,NavComponent],
  imports: [
    CommonModule
  ]
})
export class SharedModuleModule { }
