/* eslint-disable no-underscore-dangle */
import { Router } from '@angular/router';
/* eslint-disable no-trailing-spaces */
/* eslint-disable @typescript-eslint/semi */
import { Component, Input, NgZone, OnInit } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';


@Component({
  selector: 'app-response-no-location',
  templateUrl: './response-no-location.component.html',
  styleUrls: ['./response-no-location.component.scss'],
})
export class ResponseNoLocationComponent implements OnInit {

  watchId: any;
  isEnableVisible: boolean = true;
  @Input() public content;
  // eslint-disable-next-line no-trailing-spaces

  constructor(
    public activeModal: NgbActiveModal,
    public ngZone: NgZone,
    private geolocation: Geolocation,
    private _router: Router
  ) { }

  ngOnInit() {

  }

  askforLocation(){
    this.geolocation.getCurrentPosition().then((resp) => {
      this.activeModal.close('Close click');
      location.reload();
    }).catch((error) => {
      this.isEnableVisible = false;
      this.content = {
        "title": "Error",
        "content": `It seems like you blocked the location persmission for this website. To allow permission, click the <i class="mdi mdi-information"></i> icon beside the URL, then beside the Location, select Allow in the dropdown.`
      }
      console.error('Error getting location', error);
    });
  }




}

