/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/dot-notation */
import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { first, map } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})


export class LocationService {
  public recordSubject: BehaviorSubject<any>;
  public record: Observable<any>;

  constructor(private geolocation: Geolocation) {
    this.recordSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('location')));
    this.record = this.recordSubject.asObservable();
  }

  public get locationValue() {
    return this.recordSubject.value;
  }

  setLocation(locationData){
    localStorage.setItem('location',JSON.stringify(locationData));
    this.recordSubject.next(locationData);
  }

  resetLocation(){
    this.recordSubject.next(null);
    localStorage.removeItem('location');
  }


  checkPermission() {
    //navigator.permissions.query({name:'geolocation'}).then( (result) => result.state );
    return from(navigator.permissions.query({name:'geolocation'})).pipe(map( x=> x.state ));
  }


}
